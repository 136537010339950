import React from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';

export const Navbar = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const scrollToSection = (sectionId, event) => {
    event.preventDefault();
    
    // If we're not on the homepage, navigate there first
    if (location.pathname !== '/') {
      navigate('/', { state: { scrollTo: sectionId } });
    } else {
      // If we're already on homepage, just scroll
      const targetElement = document.getElementById(sectionId);
      if (targetElement) {
        const headerOffset = sectionId === 'productshowcase' ? 100 : 
                           sectionId === 'featurecards' ? 10 : 
                           30; // for pricing
        const elementPosition = targetElement.getBoundingClientRect().top;
        const offsetPosition = elementPosition + window.pageYOffset - headerOffset;

        window.scrollTo({
          top: offsetPosition,
          behavior: 'smooth'
        });
      }
    }
  };

  return (
    <nav className="fixed top-0 left-0 right-0 bg-white/30 backdrop-blur-md border-b border-gray-200/20 z-50">
      <div className="max-w-[1400px] mx-auto px-4 sm:px-6">
        <div className="flex items-center h-[60px]">
          {/* Left section: Logo + Company name */}
          <Link to="/" className="w-1/4 flex items-center flex-shrink-0">
            <img src="/BWtransparentlogo.png" alt="Lyra Logo" className="mr-2 w-10 h-10" />
            <div className="inline-flex items-center">
              <span className="text-[16px] font-bold text-gray-800 leading-none">LyraVet</span>
            </div>
          </Link>

          {/* Middle section: Navigation links */}
          <div className="w-2/4 hidden md:flex items-center justify-center">
            <div className="flex space-x-8">
              <a href="#productshowcase" onClick={(e) => scrollToSection('productshowcase', e)} className="text-[14.5px] font-medium text-gray-800 hover:text-gray-900 hover:underline transition-colors duration-200">Product</a>
              <a href="#featurecards" onClick={(e) => scrollToSection('featurecards', e)} className="text-[14.5px] font-medium text-gray-800 hover:text-gray-900 hover:underline transition-colors duration-200">Features</a>
              <a href="#pricing" onClick={(e) => scrollToSection('pricing', e)} className="text-[14.5px] font-medium text-gray-800 hover:text-gray-900 hover:underline transition-colors duration-200">Pricing</a>
            </div>
          </div>

          {/* Right section: Auth buttons */}
          <div className="w-1/4 flex items-center justify-end space-x-4">
            <Link to="/login" className="text-[14.5px] font-medium text-gray-800 hover:text-gray-900 hover:underline transition-colors duration-200">
              Log in
            </Link>
            <Link 
              to="/signup"
              className="bg-[#00B8D4] text-white px-6 py-2 rounded-lg hover:bg-[#008CA1] transition-colors text-base font-medium min-w-[160px] text-center"
            >
              Start Free Trial
            </Link>
          </div>
        </div>
      </div>
    </nav>
  );
};
