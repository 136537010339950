import React from 'react';
import { Illustration } from './Illustration';

const TrustedBy = () => (
  <div className="mt-8">
    <p className="text-sm text-gray-500 mb-4">Integrates with leading veterinary software</p>
    <div className="flex flex-wrap items-center justify-center lg:justify-start gap-8"> {/* Reduced from gap-8 */}
      <img 
        src="/pulse-logo.png" 
        alt="Pulse" 
        className="h-14 grayscale opacity-50 hover:opacity-100 transition-opacity duration-200" // Reduced from h-8
      />
      <img 
        src="/impromed-logo.png" 
        alt="Impromed" 
        className="h-14 grayscale opacity-50 hover:opacity-100 transition-opacity duration-200" // Reduced from h-7
      />
      <img 
        src="/avimark-logo.png" 
        alt="Avimark" 
        className="h-14 grayscale opacity-50 hover:opacity-100 transition-opacity duration-200" // Reduced from h-7
      />
    </div>
  </div>
);

export const Hero = () => {
  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 pt-20 pb-2"> {/* Reduced from pb-16 */}
      <div className="flex flex-col lg:flex-row items-center gap-12">
        <div className="flex-1 text-center lg:text-left">
          <h1 className="text-6xl lg:text-7xl font-bold leading-[1.1] tracking-tight">
          Organize.
            <br />
            Streamline.
            <br />
            Care.
          </h1>
          <p className="text-lg text-gray-600 mt-6 mb-8 max-w-3xl whitespace-nowrap">
            Your space to streamline veterinary work and enhance patient care.
          </p>
          <div className="flex flex-col sm:flex-row justify-center lg:justify-start gap-3">
            <a 
              href="https://calendly.com/admin-lyravet/demo"
              target="_blank"
              rel="noopener noreferrer"
              className="bg-[#00B8D4] text-white px-6 py-2 rounded-lg hover:bg-[#008CA1] transition-colors text-base font-medium min-w-[160px] text-center"
            >
              Request a demo
            </a>
          </div>
          <TrustedBy />
        </div>
        <div className="flex-1 scale-150 mr-[30px] overflow-visible">
          <Illustration />
        </div>
      </div>
    </div>
  );
};