import React from 'react';
import SpaceBackground from '../components/background/SpaceBackground';
import CelestialDecorations from '../components/background/CelestialDecorations';

const ComingSoonPage = () => {
  return (
    <div className="relative">
      <SpaceBackground>
        <CelestialDecorations />
        <div className="min-h-screen bg-transparent flex items-center justify-center">
          <div className="max-w-[600px] mx-auto px-4 text-center">
            <img 
              src="/BWtransparentlogo.png" 
              alt="Lyra Logo" 
              className="w-24 h-24 mx-auto mb-8" 
            />
            <h1 className="text-5xl font-bold text-gray-800 mb-6">
              Coming Soon
            </h1>
            <p className="text-xl text-gray-600 mb-12">
              We're working on something amazing. Our new website will be launching soon.
            </p>
            <div className="space-y-6">
              <p className="text-gray-600">
                For inquiries, please contact us at:{' '}
                <a 
                  href="mailto:contact@vetreum.com" 
                  className="text-[#00B8D4] hover:text-[#008CA1]"
                >
                  contact@vetreum.com
                </a>
              </p>
              <div className="flex justify-center space-x-6">
                <a 
                  href="https://calendly.com/admin-lyravet/demo"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="px-8 py-3 bg-[#00B8D4] hover:bg-[#008CA1] text-white 
                           rounded-lg font-medium transition-colors"
                >
                  Request Demo
                </a>
              </div>
            </div>
          </div>
        </div>
      </SpaceBackground>
    </div>
  );
};

export default ComingSoonPage;